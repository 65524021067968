import React from 'react'
import { graphql } from 'gatsby'
import { Combo, ModelFeatures, ClickToCall, SimpleHero } from '@layouts'
import { Layout, SEO } from '@globals'
import { Helmet } from 'react-helmet'

const ModelFeatureTemplate = ({ data, pageContext }) => {
  const { name, body, features, slug, brochureEmbed } = data.sanityModel

  return (
    <>
      <SEO title={name} description={body} disallowRobots="false" />
      <Layout>
        <SimpleHero
          headingLineOne={pageContext.headingLineOne}
          headingLineTwo={pageContext.headingLineTwo}
          body={pageContext.body}
          backgroundImage={pageContext.image}
        />
        {pageContext.details.length === 1 &&
          pageContext.details.map((data, idx) => {
            return (
              <SimpleHero
                key={idx}
                heading={data.heading}
                copy={data.body}
                backgroundImage={data.image}
              />
            )
          })}
        <div className="relative bg-white">
          {pageContext.details.length > 1 && (
            <div>
              <div className="text-center">
                <h2 className="text-lg tracking-wider uppercase font-bold text-brand-gray-500">
                  WHEN YOU KNOW THE DIFFERENCE<sup>SM</sup>
                </h2>
              </div>
              {pageContext.details.map((data, idx) => {
                return (
                  <Combo
                    key={idx}
                    heading={data.heading}
                    copy={data.body}
                    image={data.image}
                    alignment={idx % 2 === 0 ? `right` : `left`}
                  />
                )
              })}
            </div>
          )}
        </div>
        <ModelFeatures features={features} slug={slug} />
        <ClickToCall model={name} />
        <HubspotForm formID={brochureEmbed} />
      </Layout>
    </>
  )
}

export default ModelFeatureTemplate

export const MODEL_QUERY = graphql`
  query ($_id: String!) {
    sanityModel(_id: { eq: $_id }) {
      name
      body
      brochureEmbed
      slug {
        current
      }
      features {
        body
        headingLineOne
        headingLineTwo
        detailsHeading
        details {
          body
        }
        image {
          asset {
            source {
              url
              id
              _key
            }
            altText
            _id
            path
          }
        }
      }
    }
  }
`
class HubspotForm extends React.Component {
  componentDidMount() {
    this.initializeHubspotForm()
  }
  initializeHubspotForm() {
    ;(async () => {
      while (!window.hbspt)
        // define the condition as you like
        await new Promise((resolve) => setTimeout(resolve, 1000))
      window.hbspt.forms.create({
        portalId: '151834',
        formId: this.props.formID,
        target: '#form-container',
      })
    })()
  }
  render() {
    return (
      <div className="">
        <Helmet>
          <script src="//js.hsforms.net/forms/v2.js" type="text/javascript" />
          <script>
          var _hsq = window._hsq = window._hsq || [];
          _hsq.push(['setPath', window.location.href]);
          _hsq.push(['trackPageView']);
          </script>
        </Helmet>
        <h2 className="text-xl font-bold tracking-wider uppercase text-brand-gray-500 sm:text-2xl">
          <span className="block">Want to learn more?</span>
          <span className="block text-brand-red-200">
            Download the brochure.
          </span>
        </h2>
        <div id="form-container" className="mt-8"></div>
      </div>
    )
  }
}
